export function stringToUUID (str) {
  const withoutDashes = str.replace(/-/g, '')
  const segments = []
  let position = 0;

  // Add segments to the UUID array
  [8, 4, 4, 4, 12].forEach((segmentLength) => {
    segments.push(withoutDashes.substr(position, segmentLength))
    position += segmentLength
  })

  // Join segments with dashes
  return segments.join('-')
}
