import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './index.css'

const MainSection = ({ member }) => {
  const data = member[0]
  if (data.organization === '') {
    return <div></div>
  }
  const companies = data.organization.split('\n')
  let bgFrameClass = 'main-section p-4 pb-5 '
  if (companies.length === 1) {
    bgFrameClass += companies[0].includes('Artiverse Apparel')
      ? 'aa-logo-bg'
      : 'ba-logo-bg'
  } else {
    bgFrameClass += 'both-logo-bg'
  }
  return (
    <Row className="mt-4 d-flex align-items-center justify-content-center text-white">
      <Col md={6} sm={12} lg={6} xs={12}>
        <Container
          className={bgFrameClass}
        >
          <Row>
            <h1>{data.display_name}</h1>
          </Row>
          <Row>
            <h4>{data.title}</h4>
          </Row>
          <Row>
            <h6 className="mb-3">{data.department}</h6>
          </Row>
        </Container>
      </Col>
    </Row>
  )
}

export default MainSection
