import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ContactSection from './components/ContactSection'
import LogoSection from './components/LogoSection'
import MainSection from './components/MainSection'

import { apiKey, defaultData } from './constants/Constant'
import './css/App.css'
import { stringToUUID } from './utils/utils'

import { Helmet } from 'react-helmet'
function getCurrentDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

function App() {
  const [member, setMember] = useState([defaultData])
  const uid = stringToUUID(window.location.pathname.replace('/', ''))
  const [screenSize, setScreenSize] = useState(getCurrentDimension())
  const [metaDescription, setMetaDescription] = useState({ title: '', description: '' })

  useEffect(() => {
    fetch(
      `https://rkmybkstkkqxmlyfzlgq.supabase.co/rest/v1/member?id=eq.${uid}`,
      {
        method: 'GET',
        headers: { apikey: apiKey }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setMember(data)
        setMetaDescription({
          title: data[0].display_name,
          description: data[0].title + ' at ' + data[0].organization.split('\n').join(' & ')
        })
      })
      .catch((err) => {
        console.log(err.message)
      })
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension)
    return () => {
      window.removeEventListener('resize', updateDimension)
    }
  }, [uid, screenSize])

  const companies = member[0].organization.split('\n').filter((s) => s !== '')
  return (
    <div>
      <Helmet>
        <title>{metaDescription.title}</title>
        <meta name="description" content={metaDescription.description} />
      </Helmet>
      <Container className="p-5">
        <Row className="justify-content-center text-center">
          <Col md={12} sm={12} lg={12} className="heading-one text-center">
            <h3 className="text-style" style={{ whiteSpace: 'pre', 'text-wrap': 'wrap' }}>
              {companies.map((company, index) => {
                return (
                  <span key={index}>
                    {company}
                    {index < companies.length - 1 && screenSize.width > 770
                      ? '\t~\t'
                      : '\n'}
                  </span>
                )
              })}
            </h3>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          {companies.map((company, index) => {
            return (
              <LogoSection
                key={index}
                company={company}
                includeBorder={index < 1 && companies.length > 1}
              />
            )
          })}
        </Row>
        <MainSection member={member} />
        <ContactSection member={member} />
      </Container>
    </div>
  )
}

export default App
