import { VCard } from '@covve/easy-vcard'
import {
  faEnvelope,
  faLocationDot,
  faMobileAlt,
  faPhoneAlt,
  faPlusCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { defaultData } from '../../constants/Constant'
import './index.css'

function formatHKPhone (data) {
  if (!data) return ''
  return data.replace('852', '852-')
}

const ContactSection = ({ member }) => {
  const data = member[0] ?? defaultData
  const address =
    data.address !== ''
      ? [data.address, data.postal_code, data.city, data.country].join(', ')
      : ''
  const phone = formatHKPhone(data.phone_main)
  const directPhone = formatHKPhone(data.phone_direct)
  const mobile = formatHKPhone(data.phone_mobile)
  const email = data.email

  return (
    <div className='new-line'>
      <Row className="mt-3 p-1 d-flex align-items-center justify-content-center">
        <Col
          md={1}
          sm={1}
          lg={1}
          xs={1}
          className="d-flex align-items-center justify-content-center"
        >
          <FontAwesomeIcon icon={faLocationDot} size="2x" className="icons" />
        </Col>
        <Col md={5} sm={11} lg={5} xs={11}>
          <p className="m-0">{address}</p>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col md={6} sm={12} lg={6} xs={12}>
          <hr className="" />
        </Col>
      </Row>
      {directPhone.length > 0
        ? (
        <Row className="p-1 d-flex align-items-center justify-content-center">
          <Col
            md={1}
            sm={1}
            lg={1}
            xs={1}
            className="d-flex align-items-center justify-content-center"
          >
          <FontAwesomeIcon icon={faPhoneAlt} size="2x" className="icons" />
          </Col>
          <Col md={5} sm={11} lg={5} xs={11}>
            <p className="m-0">{directPhone}</p>
          </Col>
          <Row className="d-flex justify-content-center">
            <Col md={6} sm={12} lg={6} xs={12}>
              <hr className="" />
            </Col>
          </Row>
        </Row>
          )
        : (
            ''
          )}
      {phone.length > 0
        ? (
        <Row className="p-1 d-flex align-items-center justify-content-center">
          <Col
            md={1}
            sm={1}
            lg={1}
            xs={1}
            className="d-flex align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faPhoneAlt} size="2x" className="icons" />
          </Col>
          <Col md={5} sm={11} lg={5} xs={11}>
            <p className="m-0">{phone}</p>
          </Col>
          <Row className="d-flex justify-content-center">
            <Col md={6} sm={12} lg={6} xs={12}>
              <hr className="" />
            </Col>
          </Row>
        </Row>
          )
        : (
            ''
          )}
      {mobile.length > 0
        ? (
        <div>
          <Row className="p-1 d-flex align-items-center justify-content-center">
            <Col
              md={1}
              sm={1}
              lg={1}
              xs={1}
              className="d-flex align-items-center justify-content-center"
            >
              <FontAwesomeIcon icon={faMobileAlt} size="2x" className="icons" />
            </Col>
            <Col md={5} sm={11} lg={5} xs={11}>
              <p className="m-0">{mobile}</p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={6} sm={12} lg={6} xs={12}>
              <hr className="" />
            </Col>
          </Row>
        </div>
          )
        : (
            ''
          )}
      <Row className="p-1 d-flex align-items-center justify-content-center">
        <Col
          md={1}
          sm={1}
          lg={1}
          xs={1}
          className="d-flex align-items-center justify-content-center"
        >
          <FontAwesomeIcon icon={faEnvelope} size="2x" className="icons" />
        </Col>
        <Col md={5} sm={11} lg={5} xs={11}>
          <p className="m-0">{email}</p>
        </Col>
      </Row>
      <Row
        className="mt-3 p-1 d-flex align-items-center justify-content-center contact-row"
        onClick={() => saveContact(data)}
      >
        <Col
          md={1}
          sm={1}
          lg={1}
          xs={1}
          className="d-flex align-items-center justify-content-center pt-3 pb-3 contact-background"
        >
          <FontAwesomeIcon icon={faPlusCircle} className="icons" />
        </Col>
        <Col
          md={5}
          sm={11}
          lg={5}
          xs={11}
          className="pt-3 pb-3 contact-background"
        >
          <p className="m-0 text-color">
            <b>Add To My Contacts</b>
          </p>
        </Col>
      </Row>
    </div>
  )
}
function saveContact (member) {
  const card = new VCard()
    .setFullName(member.display_name)
    .addFirstName(member.first_name)
    .addLastName(member.last_name)
    .addTitle(member.title)
    .addAddress(
      member.address,
      member.postal_code,
      member.city,
      '',
      member.country
    )
    .addOrganization(member.organization, [member.department])
  const emails = member.email.split('\n')
  emails.forEach((email) => card.addEmail(email))
  if (member.phone_mobile) {
    card.addPhone(member.phone_mobile.replace('852', '+852 '), {
      type: 'cell',
      pref: '1'
    })
  }
  if (member.phone_direct) {
    card.addPhone(member.phone_direct.replace('852', '+852 '), {
      type: 'direct',
      pref: '1'
    })
  }
  if (member.phone_main) {
    card.addPhone(member.phone_main.replace('852', '+852 '), {
      type: 'main',
      pref: '1'
    })
  }

  const res = card.toString(true)
  const data = new Blob([res], { type: 'text/x-vcard' })
  const csvURL = window.URL.createObjectURL(data)
  const tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', `${member.display_name}.vcf`)
  tempLink.click()
}

export default ContactSection
