export const apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJrbXlia3N0a2txeG1seWZ6bGdxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODg5Nzg1NDQsImV4cCI6MjAwNDU1NDU0NH0.i9i9eTLcNY5BV9IxLgCVnn2Xv__19R2CpQY8q1yB8R4'
export const defaultData = {
  first_name: '',
  last_name: '',
  name_zh: '',
  display_name: '',
  email: '',
  phone_main: '',
  phone_direct: '',
  phone_mobile: '',
  address: '',
  city: '',
  postal_code: '',
  country: '',
  title: '',
  department: '',
  organization: '',
  id: ''
}
