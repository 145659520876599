import React from 'react'
import { Col } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import aaLogo from '../../images/AA logo.svg'
import baLogo from '../../images/BA logo.svg'

const LogoSection = ({ company, includeBorder }) => {
  let logo = aaLogo
  if (!company.includes('Artiverse Apparel')) {
    logo = baLogo
  }
  let classes = 'd-flex align-items-center justify-content-center '
  if (includeBorder) {
    classes += 'logo-border'
  }
  return (
    <Col md={6} sm={6} lg={4} xs={6} className={classes}>
      <Image className="image-style" fluid src={logo} alt="logo" />
    </Col>
  )
}

export default LogoSection
